import React from "react";

import styled from "styled-components/macro";

import { NavLink, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import { format, parseISO } from "date-fns";
import { pt } from "date-fns/locale";

import {
  Box as MuiBox,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { BpProjects } from "../../services";

const Box = styled(MuiBox)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

export const ProjectsRouletteBrokersPage = () => {
  const [project, setProject] = React.useState(null);

  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);

  const { projectId } = useParams();

  React.useEffect(() => {
    (async () => {
      const resBroker = await BpProjects.getById(projectId);
      setProject(resBroker.data);
    })();
  }, [projectId]);

  React.useEffect(() => {
    (async () => {
      const resList = await BpProjects.listRoulettes(projectId, page);
      setData(resList.data);
    })();
  }, [projectId, page]);

  return (
    <Box p={8}>
      <Helmet title="Roleta Virtual" />

      <Grid justify="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Lista de Corretores na Roleta
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/bp/projects">
              Empreendimentos
            </Link>
            <Link
              component={NavLink}
              exact
              to={`/bp/projects/${projectId}/edit`}
            >
              {project?.name}
            </Link>
            <Typography>Corretores na Roleta</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <div>
            <Paper>
              <TableContainer>
                <Table aria-labelledby="tableTitle" size={"medium"}>
                  <TableHead>
                    <TableRow>
                      <TableCell>CORRETOR</TableCell>
                      <TableCell>LISTA</TableCell>
                      <TableCell>CONTADOR</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.rows?.map((row, index) => {
                      return (
                        <TableRow>
                          <TableCell align="left" id={index} scope="row">
                            <strong>{row.broker.name}</strong>
                          </TableCell>

                          <TableCell align="left" id={index} scope="row">
                            {!row.list ? "Normal" : "Prioritária"}
                          </TableCell>

                          <TableCell align="left" id={index} scope="row">
                            {row.counter}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                labelDisplayedRows={({ from, to, count }) =>
                  `Página ${page + 1} de ${Math.ceil(count / 100)}`
                }
                rowsPerPageOptions={[100]}
                component="div"
                count={data.count || 0}
                rowsPerPage={100}
                page={page}
                onChangePage={(_, page) => setPage(page)}
              />
            </Paper>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
