import React, { useEffect, useState } from "react";

import { NavLink, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import { spacing } from "@material-ui/system";

import { BpBrokers, BpBrokerRoulettes } from "../../services";

import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Divider as MuiDivider,
  Card as MuiCard,
  Button,
  CardContent,
  Box,
  Grid,
  Breadcrumbs,
  Typography,
  Link,
  MenuItem,
  InputLabel,
  TextField,
} from "@material-ui/core";
import styled from "styled-components/macro";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

export const BrokersRoulletesEditPage = () => {
  const [broker, setBroker] = useState(null);
  const [roulette, setRoulette] = useState(null);
  const [status, setStatus] = useState("CREATED");
  const [comment, setComment] = useState("");
  const [list, setList] = useState("");

  const [isSubmitting, setSubmitting] = useState(false);

  const { brokerId, rouletteId } = useParams();

  React.useEffect(() => {
    (async () => {
      const resBroker = await BpBrokers.getById(brokerId);
      setBroker(resBroker.data);

      const resRoulette = await BpBrokerRoulettes.getById(brokerId, rouletteId);
      setRoulette(resRoulette.data);

      setStatus(resRoulette.data.status);
      setComment(resRoulette.data.comment);
      setList(resRoulette.data.list === null ? "" : resRoulette.data.list);
    })();
  }, [brokerId, rouletteId]);

  const submitForm = async (event) => {
    event.preventDefault();
    setSubmitting(true);

    const form = {
      status,
      comment,
      list: list.length === 0 ? null : list,
    };

    try {
      await BpBrokerRoulettes.edit(brokerId, rouletteId, form);

      alert("Corretor atualizado com sucesso");

      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <Box p={8}>
      <Helmet title="Settings" />

      <Typography variant="h3" gutterBottom display="inline">
        Editar Roleta
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Inicio
        </Link>
        <Link component={NavLink} exact to="/bp/brokers">
          Corretores
        </Link>
        <Link component={NavLink} exact to={`/bp/brokers/${brokerId}/edit`}>
          {broker?.name}
        </Link>
        <Link
          component={NavLink}
          exact
          to={`/bp/brokers/${brokerId}/roulettes/`}
        >
          Roletas
        </Link>
        <Typography>{roulette?.project?.name}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Preencha os dados abaixo para editar os dados da roleta
              </Typography>

              {isSubmitting ? (
                <CircularProgress variant="indeterminate" color="red" />
              ) : (
                <Box pt={4}>
                  <Grid container spacing={6}>
                    <Grid item md={4}>
                      <TextField
                        label="Status"
                        onChange={(event) => setStatus(event.target.value)}
                        value={status}
                        select
                        my={2}
                        fullWidth
                        variant="outlined"
                      >
                        <MenuItem value="CREATED">Não solicitado</MenuItem>
                        <MenuItem value="IN_ANALYSIS">Em Análise</MenuItem>
                        <MenuItem value="APPROVED">Aprovado</MenuItem>
                        <MenuItem value="REPROVED">Reprovado</MenuItem>
                      </TextField>
                    </Grid>

                    <Grid item md={8}>
                      <TextField
                        id="comment"
                        label="Comentário"
                        variant="outlined"
                        fullWidth
                        onChange={(event) => setComment(event.target.value)}
                        value={comment}
                        my={2}
                      />
                    </Grid>

                    <Grid item md={4}>
                      <TextField
                        label="List"
                        onChange={(event) => setList(event.target.value)}
                        value={list}
                        select
                        my={2}
                        fullWidth
                        variant="outlined"
                      >
                        <MenuItem value="">Normal</MenuItem>
                        <MenuItem value="priority">Prioritária</MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>

                  <hr />

                  <Button
                    onClick={submitForm}
                    variant="contained"
                    fullWidth
                    color="primary"
                    mt={3}
                  >
                    Salvar
                  </Button>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
